// =========================================================
// * Vue Material Dashboard - v1.5.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import Vuex from 'vuex'
import VueRouter from "vue-router";
import App from "./App";
import axios from 'axios'
import VueAxios from 'vue-axios'

// router setup
import routes from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
// import Notifications from "./components/NotificationPlugin";
import { Vue2Modal } from "@arfedulov/vue2-modal";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

// Websocket
import socket from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'

import Chartist from "chartist";

// configure router
const router = new VueRouter({
    routes, // short for routes: routes
    linkExactActiveClass: "nav-item active",
});

const socketUrl = process.env.VUE_APP_SOCKET_PORT === 80 || process.env.VUE_APP_SOCKET_PORT === 443
    ? process.env.VUE_APP_API_URL
    : `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_SOCKET_PORT}`
;

// configure websocket
Vue.use(new VueSocketIO({
    debug: true,
    connection: socket(socketUrl),
}))

Vue.prototype.$Chartist = Chartist;

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(VueAxios, axios)
Vue.use(Vuex);
Vue.use(Vue2Modal);

Vue.component('v-select', vSelect)


const store = new Vuex.Store({
    state: {
        leaderboard: null,
        fontSize: 25,
        showTotalScore: false,
    },
    mutations: {
        updateLeaderboard (state, data) {
            // Vue.set(state, 'leaderboard', data);
            this.state.leaderboard = data;
        },
        updateFontSize (state, data) {
            // Vue.set(state, 'leaderboard', data);
            this.state.fontSize = data;
        },
        updateShowTotalScore (state, data) {
            // Vue.set(state, 'leaderboard', data);
            console.log("Update total score");
            this.state.showTotalScore = data;
        },
        updateScore (state, data) {
            let leaderboard = JSON.parse(JSON.stringify(state.leaderboard));

            for (let roundIndex of Object.keys(leaderboard.rounds)) {
                let round = leaderboard.rounds[roundIndex];

                for (let groupIndex of Object.keys(round.groups)) {
                    let group = round.groups[groupIndex];

                    for (let gameIndex of Object.keys(group.games)) {
                        let game = group.games[gameIndex];

                        for (let scoreIndex of Object.keys(game.scores)) {
                            let score = game.scores[scoreIndex];

                            if (score.id === data.id) {

                                leaderboard.rounds[roundIndex].groups[groupIndex].games[gameIndex].scores[scoreIndex] = data;
                                this.commit('updateLeaderboard', leaderboard);
                                return;
                            }
                        }
                    }
                }
            }
        },
        addScore (state, data) {
            let leaderboard = JSON.parse(JSON.stringify(state.leaderboard));
            delete data.data.score.boardId;
            delete data.data.score.game
            data.data.score.team = leaderboard.teams.find((obj) => { return obj.id === data.data.score.team.id; });

            for (let roundIndex of Object.keys(leaderboard.rounds)) {
                let round = leaderboard.rounds[roundIndex];

                for (let groupIndex of Object.keys(round.groups)) {
                    let group = round.groups[groupIndex];

                    for (let gameIndex of Object.keys(group.games)) {
                        let game = group.games[gameIndex];
                        if (game.id === data.data.game) {
                            leaderboard.rounds[roundIndex].groups[groupIndex].games[gameIndex].scores.push(data.data.score);
                            this.commit('updateLeaderboard', leaderboard);
                            return;
                        }
                    }
                }
            }
        },
        updateGroup (state, data) {
            let leaderboard = JSON.parse(JSON.stringify(state.leaderboard));

            for (let roundIndex of Object.keys(leaderboard.rounds)) {
                let round = leaderboard.rounds[roundIndex];

                for (let groupIndex of Object.keys(round.groups)) {
                    let group = round.groups[groupIndex];
                    if (group.id === data.data.id) {

                        leaderboard.rounds[roundIndex].groups[groupIndex] = data.data;
                        this.commit('updateLeaderboard', leaderboard);
                        return;
                    }
                }
            }
        },
        updateTeam (state, data) {
            let leaderboard = JSON.parse(JSON.stringify(state.leaderboard));
            if (leaderboard.teams) {
                let team = leaderboard.teams.findIndex(function (team) { return team.id === data.data.id })
                leaderboard.teams[team] = data.data;
            }

            this.commit('updateLeaderboard', leaderboard);
            return;
        },
        setActiveRound(state, data) {
            let leaderboard = JSON.parse(JSON.stringify(state.leaderboard));
            let activeRound = leaderboard.rounds.find((round) => { return round.id === data.data});
            leaderboard.activeRound = activeRound;

            this.commit('updateLeaderboard', leaderboard);
            return;
        }
    },
    getters: {
      standings (state) {
        return state.todos.filter(todo => todo.done)
      }
    }
});

/* eslint-disable no-new */
new Vue({
    el: "#app",
    store: store,
    axios: axios,
    render: (h) => h(App),
    router,
    data: {
        Chartist: Chartist,
    },
});
