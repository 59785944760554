<template>
    <div class="content">
        <div style="display: flex; justify-content: flex-end; width: 100%; margin-bottom: 20px;">
            <md-button class="md-dense md-primary" style="margin: 0 3px;" @click="openEditRoundModal({ id: null, name: null })">
                <md-icon>add</md-icon> Ronde toevoegen
            </md-button>
        </div>
        <div class="md-layout">
            <template v-if="$store.state.leaderboard">
                <div
                    v-for="round of roundsData"
                    :key="round.id"
                    class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
                >
                    <md-card>
                        <md-card-header data-background-color="green" style="display: flex; justify-content: space-between;">
                            <h4 class="title">{{ round.name }}</h4>

                            <div>
                                <md-button class="md-icon-button md-dense md-primary" style="margin: 0 3px;" @click="openEditRoundModal(round)">
                                    <md-icon>edit</md-icon>
                                </md-button>
                                <md-button class="md-icon-button md-dense md-danger" style="margin: 0 3px;" @click="confirmDeleteRound(round)">
                                    <md-icon>delete</md-icon>
                                </md-button>
                            </div>
                        </md-card-header>
                        <md-card-content>

                            <md-table v-model="round.groups" table-header-color="green">
                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Groep">
                                        <div style="display: flex; justify-content: space-between; line-height: 32px; width: 100%;">
                                            <div>{{ item.name }}</div>
                                            <div>
                                                <md-button class="md-icon-button md-dense" style="margin: 0 3px;" @click="openEditGroupModal(item, round)">
                                                    <md-icon>edit</md-icon>
                                                </md-button>
                                                <md-button class="md-icon-button md-dense md-danger" style="margin: 0 3px;" @click="confirmDeleteGroup(item)">
                                                    <md-icon>delete</md-icon>
                                                </md-button>
                                            </div>
                                        </div>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>

                            <md-button class="md-dense md-primary" style="margin: 0 3px;" @click="openEditGroupModal({ id: null, name: null }, round)">
                                <md-icon>add</md-icon> Groep toevoegen
                            </md-button>

                        </md-card-content>
                    </md-card>
                </div>
            </template>
        </div>

        <v-modal
            v-if="editModalData"
            ref="editRoundModal"
            target="modal-target"
            close-on-backdrop-click
        >
            <w-j-modal :title="editModalData.title">


                <md-field>
                    <label>Naam</label>
                    <md-input v-model="editModalData.item.name" @keydown.enter.prevent="editModalData.submitAction()" />
                </md-field>

                <v-select 
                    v-if="editModalData.type === 'group'"
                    v-model="editModalData.item.round" 
                    class="modal-select"
                    placeholder="ronde"
                    label="name" 
                    :options="roundsData" 
                    :reduce="item => item.id"
                />

                <v-select 
                    v-if="editModalData.type === 'group'"
                    v-model="editModalData.item.type" 
                    class="modal-select"
                    placeholder="type"
                    label="label" 
                    :options="groupTypes" 
                    :reduce="item => item.type"
                />

                <div slot="footer">
                    <div style="text-align: right">
                        <md-button @click="$refs.editRoundModal.close();">Sluiten</md-button>
                        <md-button class="md-primary" v-if="editModalData.item.id" @click="editModalData.submitAction()">Bewerken</md-button>
                        <md-button class="md-primary" v-else @click="editModalData.submitAction()">Toevoegen</md-button>
                    </div>
                </div>
            </w-j-modal>
        </v-modal>

        <v-modal
            v-if="this.deleteModalData"
            ref="confirmDeleteModal"
            target="modal-target"
            close-on-backdrop-click
        >
            <w-j-modal :title="deleteModalData.title">

                {{ deleteModalData.text }}

                <div slot="footer">
                    <div style="text-align: right">
                        <md-button @click="$refs.confirmDeleteModal.close();">Sluiten</md-button>
                        <md-button class="md-danger" style="margin-left: 10px;" @click="deleteModalData.submitAction">Verwijderen</md-button>
                    </div>
                </div>
            </w-j-modal>
        </v-modal>

    </div>
</template>

<style scoped>
    .modal-teams {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        width: 100%;
        padding: 10px;
        border-radius: 2px;
        box-shadow: 0 0 1px 1px rgb(0 0 0 / 10%);
        margin: 5px 0;
    }

    .modal-select {
        margin: 10px 0;
    }
</style>

<script>

import { VModal } from "@arfedulov/vue2-modal";
import WJModal from '../components/Modals/WJModal.vue';

export default {

    components: {
        VModal,
        WJModal
    },

    data() {
        return {
            editModalData:   null,
            deleteModalData: null,
            groupTypes: [
                { type: 'poule',      label: 'Poule' },
                { type: 'game',       label: 'Wedstrijd' },
                { type: 'highscore',  label: 'Highscore' },
            ],
        };
    },

    computed: {
        roundsData() {
            let rounds = JSON.parse(JSON.stringify(this.$store.state.leaderboard.rounds));
            for (let round of rounds) {
                round.groups.sort(this.sortByName);
            }
            return rounds.sort(this.sortByName)
        },
    },

    methods: {
        sortByName(a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
        },

        openEditRoundModal(round) {
            let action = round.id ? 'bewerken' : 'toevoegen';
            let data = {
                type:         'round',
                item:         round,
                title:        `Ronde ${action}`,
                submitAction: this.handleUpdateRound
            }
            this.openEditModal(data);
        },

        async openEditModal(data) {
            this.editModalData = data;
            await this.$nextTick();
            this.$refs.editRoundModal.open();
        },

        handleUpdateRound() {
            let params     = JSON.parse(JSON.stringify(this.editModalData.item));
            params.board   = sessionStorage.getItem('boardId');
            params.boardId = sessionStorage.getItem('boardId');
            if (params.id) {
                this.$socket.emit('round.update', params, async () => {
                    await this.$refs.editRoundModal.close();
                    this.editRoundModal = null;
                });
            } else {
                delete params.id;
                this.$socket.emit('round.create', params, async () => {
                    await this.$refs.editRoundModal.close();
                    this.editRoundModal = null;
                });
            }
        },

        openEditGroupModal(group, round) {
            let action = group.id ? 'bewerken' : 'toevoegen';
            group.round = { id: round.id, name: round.name };
            let data = {
                type:         'group',
                item:         group,
                title:        `Groep ${action}`,
                submitAction: this.handleUpdateGroup
            }
            this.openEditModal(data);
        },

        handleUpdateGroup() {
            let params     = JSON.parse(JSON.stringify(this.editModalData.item));
            params.board   = sessionStorage.getItem('boardId');
            params.boardId = sessionStorage.getItem('boardId');
            if (params.id) {
                this.$socket.emit('group.update', params, async () => {
                    await this.$refs.editRoundModal.close();
                    this.editRoundModal = null;
                });
            } else {
                delete params.id;
                this.$socket.emit('group.create', params, async () => {
                    await this.$refs.editRoundModal.close();
                    this.editRoundModal = null;
                });
            }
        },

        confirmDeleteRound(round) {
            let data = {
                id:           round.id,
                title:        "Ronde verwijderen bevestigen",
                text:         `Weet je zeker dat je de ronde ${round.name} wil verwijderen?`,
                submitAction: this.deleteRound,
            }
            this.confirmDelete(data);
        },

        confirmDeleteGroup(group) {
            let data = {
                id:           group.id,
                title:        "Groep verwijderen bevestigen",
                text:         `Weet je zeker dat je de groep ${group.name} wil verwijderen?`,
                submitAction: this.deleteGroup,
            }
            this.confirmDelete(data);
        },

        async confirmDelete(data) {
            this.deleteModalData = data;
            await this.$nextTick();
            await this.$refs.confirmDeleteModal.open();
        },

        deleteRound() {
            this.$socket.emit(
                'round.delete', 
                { 
                    boardId: sessionStorage.getItem('boardId'), 
                    id:      this.deleteModalData.id
                },
                async () => { 
                    await this.$refs.confirmDeleteModal.close();
                    this.deleteModalData = null;
                }
            );
        },

        deleteGroup() {
            this.$socket.emit(
                'group.delete', 
                { 
                    boardId: sessionStorage.getItem('boardId'), 
                    id:      this.deleteModalData.id
                },
                async () => { 
                    await this.$refs.confirmDeleteModal.close();
                    this.deleteModalData = null;
                }
            );
        }
    }
};
</script>
