<template>
    <div class="content">
        <div class="md-layout">
            <div
                class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33"
            >

                <md-field>
                    <label for="activeRound">Actieve ronde</label>
                    <md-select v-model="activeRound" name="activeRound" id="activeRound" @input="handleRoundChange">
                        <md-option v-for="round of roundsData" :key="round.id" :value="round.id">{{ round.name }}</md-option>
                    </md-select>
                </md-field>

                <md-field>
                    <label for="fontSize">Lettergrootte</label>
                    <md-input v-model="fontSize" name="fontSize" id="fontSize" @keydown.enter.prevent="handleFontSizeChanged" type="number" />
                </md-field>

                <md-checkbox label="test" v-model="showTotalScore" name="showTotalScore" id="showTotalScore" @change="toggleShowTotalScore">
                    Totaalscore weergeven
                </md-checkbox>

                <br>

                <md-button @click="refresh">
                    <md-icon>refresh</md-icon>
                </md-button>

            </div>
        </div>
    </div>
</template>

<style>
</style>

<script>

export default {
    data() {
        return {
            activeRound:     null,
            emitActiveRound: true,
            fontSize:        25,
            showTotalScore:  false,
        };
    },

    sockets: {
        'leaderboard.update': function (data) {
            if (data.data.activeRound.id !== this.activeRound) {
                this.emitRound   = false;
                this.activeRound = data.data.activeRound.id;
                this.emitRound   = true;
            }
        }
    },

    computed: {
        roundsData() {
            if (! this.$store.state.leaderboard) return [];
            let rounds = JSON.parse(JSON.stringify(this.$store.state.leaderboard.rounds));
            for (let round of rounds) {
                round.groups.sort(this.sortByName);
            }
            return rounds.sort(this.sortByName)
        },
    },

    methods: {
        sortByName(a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
        },

        handleRoundChange(activeRound) {
            if (this.emitActiveRound) {
                this.$socket.emit(
                    'board.setActiveRound', 
                    { 
                        board:       sessionStorage.getItem("boardId"),
                        boardId:     sessionStorage.getItem("boardId"),
                        activeRound: activeRound 
                    }, 
                    () => {
                        // console.log(response);
                    }
                )
            }
        },

        handleFontSizeChanged(event) {
          this.$socket.emit(
              'board.setFontSize',
              {
                board:       sessionStorage.getItem("boardId"),
                boardId:     sessionStorage.getItem("boardId"),
                fontSize:    event.target.value,
              }
          )
        },

        toggleShowTotalScore() {
          // this.showTotalScore = ! this.showTotalScore;
          this.$socket.emit(
              'board.updateShowTotalScore',
              {
                board:          sessionStorage.getItem("boardId"),
                boardId:        sessionStorage.getItem("boardId"),
                showTotalScore: this.showTotalScore,
              }
          )
        },

        refresh() {
            this.$socket.emit('board.refresh');
        },
    }
};
</script>
