<template>
    <div class='group__wrapper'>
        <div class="group__title">{{ value.name }}</div>
        <transition-group
            name="flip-list"
            tag="ul"
            class="group__list"
        >
<!--            :style="`max-height: calc(72px * (${Math.ceil(standings.length / 2)}))`"-->
            <li
                v-for="(score, index) of standings"
                :key="score.team.id"
                class="group__team"
            >
                <div class="score__main">
                    <div v-if="value.type === 'highscore'">{{ index + 1 }}. </div>
                    <div class="score__team">{{ score.team.name }}</div>
                    <div style="flex: 1;" />
                    <div class="score__total">{{ score.total }}</div>
                    <div v-if="$store.state.showTotalScore && score.totalScore !== undefined" class="score__totalScore">({{ score.totalScore }})</div>
                </div>
            </li>
        </transition-group>
    </div>
</template>

<style lang="scss">
.flip-list-move {
        transition: transform 1s;
    }

    .group {
        &__wrapper{
            background: rgba(255, 255, 255, 0.5);
            margin: 0 auto;
            padding: 15px;
            backdrop-filter: blur(6px);
            //max-width: 450px;
            border-radius: 3px;   
            font-size: 1em; 

            // @media (max-width: 1440px) {
            //     font-size: 26px;
            // }
            // @media (min-width: 1441px) {
            //     font-size: 35px;
            // }
        }

        &__title {
            font-weight: bold;
            font-size: 1em;
            text-align: center;
            line-height: 1.125em;
            // border: 1px solid #fff;
            background: #F25206;
            color: #fff;
            padding: 5px;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            // background: rgba(255, 255, 255, 0.7)
        }

        &__list {
            margin: 10px 0 0 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 10px;
            //flex-wrap: wrap;
        }

        &__team {
            background: #fff;
            // box-shadow: 0 0 5px 5px rgba(0,0,0,0.05);
            border-radius: 2px;
            list-style: none;
            color: #333;
            font-weight: bold;
            padding: 10px;
            height: 62px;
            font-size: 0.875em;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            //max-width: calc(50% - 5px)
        }
    }

    .score {
        &__main {
            display: flex;
            justify-content: space-between;
            align-items: center;
            min-height: 42px;
            gap: 5px;
        }
        &__team {
            line-height: 1em;
        }
        &__total {
            text-align: right;
        }

        &__totalScore {
            font-size: .625em;
            font-style: italic;
            color: #999999;
            min-width: 2.5em;
            text-align: right;
        }
    }
</style>

<script>
export default {
    name: "LeaderboardGroup",

    props: {
        value: { type: Object, required: true }
    },

    data() {
        return {
            showTotalScore: false,
        }
    },

    computed: {
        standings() {
            let standings = [];

            for (let game of this.value.games) {

                for (let team of game.teams) {
                    let teamId = team.id || team;
                    let storeTeam = this.$store.state.leaderboard.teams.find((obj) => { return obj.id === teamId });

                    if (! standings.find((item) => { return item.team.id === team.id })) {
                        standings.push({ 
                            team:       storeTeam,
                            scores:     [],
                            total:      0,
                            totalScore: 0,
                        });
                    }
                }

                if (this.value.type === 'poule') {
                    if (game.scores.length >= 2) {
                        game.scores.sort((a,b) => {
                            return new Date(a.updated_at) - new Date(b.updated_at);
                        });
                        let score1                = game.scores[0].score;
                        let score2                = game.scores[1].score;
                        game.scores[0].poule      = this.calcPoulePoints(score1, score2);
                        game.scores[1].poule      = this.calcPoulePoints(game.scores[1].score, score1);
                    } else if (game.scores.length === 1) {
                        game.scores[0].poule = 3;
                    }
                }

                for (let score of game.scores) {
                    let teamScore = standings.find((item) => { return item.team.id === score.team.id });
                    if (teamScore) {
                        if (this.value.type === 'poule') {
                            if (score.poule !== undefined) {
                                teamScore.scores.push(score.score);
                                teamScore.total      += Number(score.poule);
                                teamScore.totalScore += Number(score.score);
                            }
                        } else {
                            teamScore.scores.push(score.score);
                            teamScore.total += Number(score.score);   
                        }
                    }
                }
            }

            standings.sort((a, b) => {
                if (a.total < b.total) { return 1; }
                if (a.total > b.total) { return -1; }

                if (a.totalScore < b.totalScore) { return 1; }
                if (a.totalScore > b.totalScore) { return -1; }

                if (a.team.name < b.team.name) { return -1; }
                if (a.team.name > b.team.name) { return 1; }
                return 0;
            });

            return standings;
        }
    },

    methods: {
        calcPoulePoints(score1, score2) {
            if (Number(score1) === 0 && Number(score2) === 0) { return 0; }
            if (Number(score1) > Number(score2)) { return 3; }
            if (Number(score1) < Number(score2)) { return 0; }
            return 1;
        },
    }
};
</script>