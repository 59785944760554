<template>
    <div class="content">
        <div class="md-layout">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card v-if="$store.state.leaderboard">
                    <md-card-header data-background-color="green" style="display: flex; justify-content: space-between;">
                        <h4 class="title">Alle teams</h4>
                        <md-button class="md-icon-button md-dense md-primary" @click="openEditModal({id: null, name: ''})">
                            <md-icon>add</md-icon>
                        </md-button>
                    </md-card-header>
                    <md-card-content>

                        <md-table v-model="teams" table-header-color="green">
                            <md-table-row slot="md-table-row" slot-scope="{ item }">
                                <md-table-cell md-label="Team">
                                    <div style="display: flex; justify-content: space-between; line-height: 32px; width: 100%;">
                                        <div>{{ item.name }}</div>
                                        <div>
                                            <md-button class="md-icon-button md-dense" style="margin: 0 3px;" @click="openEditModal(item)">
                                                <md-icon>edit</md-icon>
                                            </md-button>
                                            <md-button class="md-icon-button md-dense md-danger" style="margin: 0 3px;" @click="confirmDelete(item)">
                                                <md-icon>delete</md-icon>
                                            </md-button>
                                        </div>
                                    </div>
                                </md-table-cell>
                            </md-table-row>
                        </md-table>

                    </md-card-content>
                </md-card>
            </div>
        </div>

        <v-modal
            ref="myModal"
            target="modal-target"
            close-on-backdrop-click
        >
            <w-j-modal title="Team toevoegen">


                <md-field>
                    <label>Team naam</label>
                    <md-input v-model="editTeam.name" @keydown.enter.prevent="handleUpdate" />
                </md-field>

                <div slot="footer">
                    <div style="text-align: right">
                        <md-button @click="$refs.myModal.close();">Sluiten</md-button>
                        <md-button class="md-primary" v-if="editTeam.id" @click="handleUpdate">Bewerken</md-button>
                        <md-button class="md-primary" v-else @click="handleUpdate">Toevoegen</md-button>
                    </div>
                </div>
            </w-j-modal>
        </v-modal>

        <v-modal
            v-if="deleteTeamObj"
            ref="confirmDeleteModal"
            target="modal-target"
            close-on-backdrop-click
        >
            <w-j-modal title="Verwijderen bevestigen">

                Weet je zeker dat je het team <b>{{ deleteTeamObj.name }}</b> wil verwijderen?

                <div slot="footer">
                    <div style="text-align: right">
                        <md-button @click="$refs.confirmDeleteModal.close();">Sluiten</md-button>
                        <md-button class="md-danger" style="margin-left: 10px;" @click="deleteTeam">Verwijderen</md-button>
                    </div>
                </div>
            </w-j-modal>
        </v-modal>
    </div>
</template>

<style scoped>

</style>

<script>

import { VModal } from "@arfedulov/vue2-modal";
import WJModal from '../components/Modals/WJModal.vue';

export default {

    components: {
        VModal,
        WJModal
    },

    data() {
        return {
            editTeam: {
                id:   null,
                name: null,
            },
            deleteTeamObj: null,
        };
    },

    computed: {
        teams() {
            let teams = JSON.parse(JSON.stringify(this.$store.state.leaderboard.teams));
            return teams.sort(this.sortByName);
        }
    },

    methods: {
        sortByName(a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
            if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
            return 0;
        },

        handleUpdate() {
            if (! this.editTeam.name) {
                return;
            }
            let params = JSON.parse(JSON.stringify(this.editTeam));
            params.board   = sessionStorage.getItem("boardId");
            params.boardId = sessionStorage.getItem("boardId");
            if (params.id) {
                this.$socket.emit('team.update', params, async () => {
                    await this.$refs.myModal.close();
                    this.editTeam = { name: null, id: null };
                });
            } else {
                delete params.id;
                this.$socket.emit('team.create', params, async () => {
                    await this.$refs.myModal.close();
                    this.editTeam = { name: null, id: null };
                });
            }
        },

        async openEditModal(team) {
            this.editTeam = team;
            await this.$refs.myModal.open();
        },

        async confirmDelete(team) {
            this.deleteTeamObj = team;
            await this.$nextTick();
            await this.$refs.confirmDeleteModal.open();
        },

        deleteTeam() {
            this.$socket.emit('team.delete', { boardId: sessionStorage.getItem("boardId"), id: this.deleteTeamObj.id }, async () => {
                await this.$refs.confirmDeleteModal.close();
                this.deleteTeamObj = null;
            })
        }
    }
};
</script>
