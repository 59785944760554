<template>
    <div style="max-width: 500px; margin-left: auto; margin-right: auto;">
        <md-card>
            <md-card-header data-background-color="green" style="display: flex; justify-content: space-between;">
                <h4 class="title">Scoreborden</h4>

                <md-button class="md-icon-button md-dense md-primary" @click="openEditModal({id: null, name: ''})">
                    <md-icon>add</md-icon>
                </md-button>
            </md-card-header>
            <md-card-content>


                <md-table v-model="boards" table-header-color="#00cc00">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Naam">
                            <div style="cursor: pointer" @click="handleRowClick(item)">{{ item.name }}!</div>
                        </md-table-cell>
                        
                        <md-table-cell style="width: 30px;" md-label="">
                            <md-button class="md-icon-button md-dense md-primary" @click="openBoard(item)" style="margin: 0">
                                <md-icon>open_in_new</md-icon>
                            </md-button>
                        </md-table-cell>
                    </md-table-row>
                </md-table>

            </md-card-content>
        </md-card>

        <v-modal
            ref="new_board"
            target="modal-target"
            close-on-backdrop-click
        >
            <w-j-modal title="Board toevoegen">

                <md-field>
                    <label>Board naam</label>
                    <md-input v-model="editBoard.name" @keydown.enter.prevent="handleUpdate" />
                </md-field>

                <div slot="footer">
                    <div style="text-align: right">
                      <md-button @click="$refs.myModal.close();">Sluiten</md-button>
                      <md-button class="md-primary" v-if="editBoard.id" @click="handleUpdate">Bewerken</md-button>
                      <md-button class="md-primary" v-else @click="handleUpdate">Toevoegen</md-button>
                    </div>
                </div>
            </w-j-modal>
        </v-modal>
    </div>
</template>

<style lang="scss">
    
</style>

<script>
import { VModal } from "@arfedulov/vue2-modal";
import WJModal from '../components/Modals/WJModal.vue';

export default {
    name: "Boards",

    components: { VModal, WJModal },

    data() {
        return {
            boards: [],
            url:   null,
            editBoard: { name: '' },
        };
    },

    async mounted() {
        this.url = process.env.VUE_APP_SOCKET_PORT === 80 || process.env.VUE_APP_SOCKET_PORT === 443
            ? process.env.VUE_APP_API_URL
            : `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_SOCKET_PORT}`
        ;

        let result = await this.axios.get(`${this.url}/api/board`);
        this.boards = result.data;
    },

    methods: {
        // TODO: Move to central location (app.vue uses the same function)
        async getLeaderboard(id) {
            let relations = [
                "rounds",
                "teams",
                "activeRound",
                "rounds.groups",
                "rounds.groups.games",
                "rounds.groups.teams",
                "rounds.groups.games.scores",
                "rounds.groups.games.scores.team",
                "rounds.groups.games.teams",
                "rounds.groups.games.teams.persons"
            ];
            
            
            const apiUrl = process.env.VUE_APP_SOCKET_PORT === 80 || process.env.VUE_APP_SOCKET_PORT === 443
                ? process.env.VUE_APP_API_URL
                : `${process.env.VUE_APP_API_URL}:${process.env.VUE_APP_SOCKET_PORT}`
            ;
            let result = await this.axios.get(`${apiUrl}/api/board/${id}`, { params: { relations }});
            this.$store.commit('updateLeaderboard', result.data);
        },
        openBoard(board) {
            window.open(`/#/leaderboard/${board.id}`);
        },

        handleRowClick(item) {
            this.goToBoard(item.id);
        },

        async goToBoard(id) {
            await this.getLeaderboard(id)
            sessionStorage.setItem('boardId', id);
            this.$router.push('dashboard');
        },

        handleUpdate() {
            if (! this.editBoard.name) {
                return;
            }
            let params     = JSON.parse(JSON.stringify(this.editBoard));
            if (! params.id) {
                delete params.id;
                this.$socket.emit('board.create', params, async (result) => {
                    await this.$refs.new_board.close();
                    this.editBoard = { name: null };
                    this.goToBoard(result.data.id)
                });
            } else {
                // this.$socket.emit('board.update', params, async () => {
                //     await this.$refs.myModal.close();
                //     this.editBoard = null;
                // });
            }
        },

        async openEditModal(board) {
            this.editBoard = board;
            await this.$refs.new_board.open()
        },
    }
}
</script>