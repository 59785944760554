<template>
    <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
        <!-- <notifications></notifications> -->

        <side-bar
            :sidebar-item-color="sidebarBackground"
            :sidebar-background-image="sidebarBackgroundImage"
        >
                <sidebar-link to="/dashboard">
                    <md-icon>dashboard</md-icon>
                    <p>Dashboard</p>
                </sidebar-link>
                <!-- <sidebar-link to="/user">
                    <md-icon>person</md-icon>
                    <p>User Profile</p>
                </sidebar-link> -->
                <sidebar-link to="/scores">
                    <md-icon>content_paste</md-icon>
                    <p>Scores</p>
                </sidebar-link>
                <sidebar-link to="/teams">
                    <md-icon>group</md-icon>
                    <p>Teams</p>
                </sidebar-link>
                <sidebar-link to="/rounds">
                    <md-icon>view_cozy</md-icon>
                    <p>Rondes</p>
                </sidebar-link>
                <sidebar-link to="/boards">
                    <md-icon>settings</md-icon>
                    <p>Scoreborden</p>
                </sidebar-link>
        </side-bar>

        <div class="main-panel">
            <top-navbar></top-navbar>

            <dashboard-content> </dashboard-content>

            <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
        </div>
    </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
// import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
// import MobileMenu from "@/pages/Layout/MobileMenu.vue";

export default {
    components: {
        TopNavbar,
        DashboardContent,
        // ContentFooter,
        // MobileMenu,
    },
    data() {
        return {
            sidebarBackground: "green",
            sidebarBackgroundImage: require("@/assets/img/sjoelen.jpeg"),
        };
    },
};
</script>
