<template>
    <transition name="fade">
        <div class="modal-wrapper">
            <div v-if="$slots.title || title" class="modal-header">
                <slot name="title">
                    <h3 class="modal-title">{{ title }}</h3>
                </slot>
            </div>

            <div class="modal-body">
                <slot />
            </div>

            <div v-if="$slots.footer" class="modal-footer">
                <slot name="footer" />
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>

    .modal-wrapper {
        max-width: 95vw;
        width: 600px;
        background: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        box-shadow: 0 0 10px 5px rgb(0 0 0 / 15%);
        color: #333;
    }

    .modal-header {
        padding: 10px 20px;
        border-bottom: 1px solid #ddd;

        h1, h2, h3, h4, h5, h6 {
            color: #333;
            &.modal-title {
                margin: 0;
                font-weight: bolder;
            }
        }
    }

    .modal-footer {
        padding: 10px 20px;
        border-top: 1px solid #ddd;
        color: #333;
        min-height: 85px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .modal-body {
        padding: 20px;
        color: #333;
        max-height: calc(95vh - 145px);
        overflow: auto;
    }

</style>

<script>
export default {
    name: "WJModal",

    props: {
        title: {
            type: String,
            default: null,
        }
    }
}
</script>