<template>
    <div class="leaderboard__wrapper" :class="wrapperClass" :style="wrapperStyle" v-if="$store.state.leaderboard">
        <div v-for="group of activeRound.groups" :key="group.id">
            <leaderboard-group :value="group" />
        </div>
    </div>
</template>

<script>

import LeaderboardGroup from './LeaderboardGroup';

export default {
    name: 'leaderboard',

    components: { LeaderboardGroup },

    props: {
        msg: String
    },

    data() {
        return {
            wrapperBackgroundImage: require("@/assets/img/sjoelen3.jpeg"),
        };
    },

    computed: {
        wrapperStyle() {
            return {
                backgroundImage: `url(${this.wrapperBackgroundImage})`,
                fontSize: this.$store.state.fontSize + "px",
            };
        },

        wrapperClass() {
            let countRounds = this.activeRound.groups.length ;
            if (countRounds > 4 && countRounds % 4 === 0) {
                return "";
            }
            if (countRounds >= 3 && countRounds <= 9 && countRounds % 3 === 0) {
                return "leaderboard__wrapper--3-col"
            }
            if (countRounds <= 4) {
                return "leaderboard__wrapper--2-col"
            }
            if (countRounds === 1) {
                return "leaderboard__wrapper--1-col"
            }
            return "";
        },

        activeRound() {

            if (! this.$store.state.leaderboard.activeRound) {
                return null;
            }
            
            // console.log(JSON.parse(JSON.stringify(this.$store.state.leaderboard.rounds)));
            let result = this.$store.state.leaderboard.rounds.find((round) => {
                return round.id === this.$store.state.leaderboard.activeRound.id;
            });

            result.groups.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });

            return result;
        }
    },

    mounted() {
        if (this.$route.params.id) {
            sessionStorage.setItem("boardId", this.$route.params.id)
        }
    },

    methods: {
        calcPoulePoints(score1, score2) {
            if (Number(score1) > Number(score2)) { return 3; }
            if (Number(score1) < Number(score2)) { return 0; }
            return 1;
        },
    }
}
</script>

<style lang="scss" scoped>

    .leaderboard {
        &__wrapper {
            width: 100vw;
            min-height: 100vh;
            background-image: URL('/assets/img/sjoelen3.jpg');
            background-attachment: fixed;
            background-size: cover;
            padding: 10px 30px;
            gap: 10px;
            display: grid; 
            grid-auto-rows: 1fr;
            cursor: none;

            &:not(&--3-col):not(&--2-col):not(&--1-col) {
                grid-template-columns: repeat(4, 1fr); 
            }

            &--3-col {
                grid-template-columns: repeat(3, 1fr); 
            }
            &--2-col {
                grid-template-columns: repeat(2, 1fr); 
            }
            &--1-col {
                grid-template-columns: repeat(1, 1fr); 
            }
        }
    }
</style>
